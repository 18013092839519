import React from 'react'
import HeaderOverlay from './HeaderOverlay'
import HeaderUnderlay from './HeaderUnderlay'

import './Header.scss'

const Header = ({ title, subheading }) => {
  return (
    <div className="header">
      <HeaderOverlay title={title} subheading={subheading}></HeaderOverlay>
      <HeaderUnderlay />
    </div>
  )
}

export default Header
