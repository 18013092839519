import React from 'react'
import { motion } from 'framer-motion'

import './HeaderOverlay.scss'

const HeaderOverlay = ({ title, subheading, children }) => {
  // const [classNames, setClassNames] = useState("header-overlay");
  // const [bgClassNames, setBgClassNames] = useState("bg-overlay");
  // useEffect(() => {
  //     const timer = setTimeout(() => {
  //       setClassNames("header-overlay clip-path");
  //       setBgClassNames("bg-overlay--hidden");
  //     }, 3000);
  //     return () => clearTimeout(timer);
  //   }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 100 }}
      transition={{ delay: 2.5, duration: 6 }}
      className="header-overlay"
    >
      {children}
      {title}
      <div className="sub-header">{subheading}</div>
    </motion.div>
  )
}

export default HeaderOverlay
