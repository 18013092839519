import { motion } from 'framer-motion'
import React, { useState } from 'react'
import Product from './Product'
import ProductInfo from './Product_List.json';

import './ProductList.scss'

const ProductList = ({ showHeader = true }) => {
  const [selectedProductId, setSelectedProductId] = useState(0)

  const products = ProductInfo.products.filter((val, i) => i < 2);

  return (
    <div className="product-list">
      {showHeader && (
        <div className="product-list__header">
          Support Parody Cinematic Universe
        </div>
      )}
      <motion.div className="product-list__scrollable">
        {products.map((product, i) => {
          return (
            <Product
              key={product.id}
              product={product}
              leftCount={0}
              isOpen={selectedProductId === product.id}
              productCount={i}
              onClick={() => {
                setSelectedProductId(product.id)
              }}
              onProductClose={() => {
                setSelectedProductId(0)
              }}
            />
          )
        })}
        {/* {!selectedProductId && products && leftCount > 0 && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: leftCount > 0 ? 1 : 0 }}
            onHoverStart={(e) => {
              setLeftArrowActive(true)
            }}
            onHoverEnd={(e) => {
              setLeftArrowActive(false)
            }}
            onClick={() => {
              setLeftCount(leftCount - 1)
            }}
            className={`product-list__left-arrow ${
              leftArrowActive ? 'left-arrow--active' : ''
            }`}
          >
            &laquo;
          </motion.div>
        )} */}

        {/* {!selectedProductId && products && leftCount < products.length - 1 && (
          <motion.div
            initial={{ opacity: 1 }}
            animate={{
              opacity: products && leftCount < products.length - 1 ? 1 : 0,
            }}
            onHoverStart={(e) => {
              setRightArrowActive(true)
            }}
            onHoverEnd={(e) => {
              setRightArrowActive(false)
            }}
            onClick={() => {
              setLeftCount(leftCount + 1)
            }}
            className={`product-list__right-arrow ${
              rightArrowActive ? 'right-arrow--active' : ''
            }`}
          >
            &raquo;
          </motion.div>
        )} */}
      </motion.div>
    </div>
  )
}

export default ProductList
