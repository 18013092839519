import React, { useEffect, useState } from 'react'
import SizeHook from '../../utils/SizeHook.js'
import Config from '../../utils/AppConfig.json'
import { motion } from 'framer-motion'
import HeaderTriangle from '../shared/MotionTriangle'

import './HeaderUnderlay.scss'

const HeaderUnderlay = () => {
  const target = React.useRef(null)
  // const { scrollYProgress } = useViewportScroll();
  const size = SizeHook(target)
  // const [currentV, setCurrentV] = useState(0);
  const [generatedTriangles, setGeneratedTriangles] = useState([])
  //const [windowHeight, setWindowHeight] = useState(0);

  // useEffect(() => {
  //     return scrollYProgress.onChange((v) => {
  //         setCurrentV(v);
  //     });
  //   }, [scrollYProgress]);

  useEffect(() => {
    if (size && size.width) {
      setGeneratedTriangles(GenerateTriangles(size.width, size.height))
      //setWindowHeight(document.body.scrollHeight);
    }
  }, [size])

  // if (currentV > 20) {
  //     generatedTriangles.forEach((triangle, i) => {
  //         // const speed = currentV * triangle.speed;
  //         // let newEndPosY = triangle.initialPosY + Math.floor((windowHeight - triangle.initialPosY) * (currentV * triangle.speed));
  //         // if (newEndPosY >= windowHeight) {
  //         //     newEndPosY = windowHeight - triangle.peakY;
  //         // }
  //         // else if (newEndPosY <= triangle.initialPosY) {
  //         //     newEndPosY = triangle.initialPosY;
  //         // }
  //         // if (i % 3 === 1) {
  //         //     triangle.scale = 1 - (currentV / 3);
  //         // } else if (i % 3 === 2) {
  //         //     triangle.scale = 1 - (currentV / 2);
  //         // }
  //         // triangle.rotation += (20 * (currentV * triangle.speed));
  //         // triangle.endPosY = newEndPosY;
  //         // triangle.opacity = 1;

  //     });
  // }
  return (
    <motion.div
      ref={target}
      className="header-underlay"
      initial={{ backgroundColor: '#fff' }}
      animate={{ backgroundColor: '#168EFF' }}
      transition={{ delay: 2.5, duration: 0.25 }}
    >
      {generatedTriangles.map((triangle, index) => {
        const styles = {}
        if (triangle.blendMode) {
          styles['mixBlendMode'] = triangle.blendMode
        }
        return (
          <HeaderTriangle
            key={`triangle${index}`}
            styles={styles}
            peakX={triangle.peakX}
            peakY={triangle.peakY}
            midPoint={triangle.mid}
            className="triangle"
            colorClassName={triangle.colorClassName}
            startPosX={triangle.posX}
            startPosY={triangle.posY}
            endPosX={triangle.endPosX}
            endPosY={triangle.endPosY}
            rotation={triangle.rotation}
            delay={triangle.delay}
            opacity={triangle.opacity}
            scale={triangle.scale}
          />
        )
      })}
    </motion.div>
  )
}

const GenerateTriangles = (frameWidth, frameHeight) => {
  const breakpoint = Config.breakpoints.find(
    (breakpoint) =>
      frameWidth >= breakpoint.minValue && frameWidth <= breakpoint.maxValue
  )

  let triangleWidth = 160
  let positionOffset = 18
  const iterations = 2
  let maxOffset = 14
  let minOffset = 6
  if (!breakpoint) {
    return []
  }
  if (breakpoint.breakpoint === 'phone') {
    triangleWidth = 60
    positionOffset = 6
    maxOffset = 6
    minOffset = 2
  } else if (breakpoint.breakpoint === 'tablet') {
    triangleWidth = 100
    positionOffset = 6
    maxOffset = 6
    minOffset = 2
  } else if (breakpoint.breakpoint === 'desktop') {
    triangleWidth = 120
    positionOffset = 8
    maxOffset = 8
    minOffset = 4
  } else if (breakpoint.breakpoint === 'hd') {
    triangleWidth = 140
    positionOffset = 16
    maxOffset = 12
    minOffset = 6
  }

  const columns = Math.floor(frameWidth / triangleWidth)
  const rows = Math.floor(frameHeight / triangleWidth)

  const max = triangleWidth + maxOffset
  const min = triangleWidth - minOffset

  let triangles = []
  for (let z = 0; z < iterations; z++) {
    for (let i = 0; i < rows; i++) {
      const midY = triangleWidth * i
      const minY = midY - positionOffset
      const maxY = midY + positionOffset
      const posY = Math.floor(Math.random() * (maxY - minY + 1) + minY)
      for (let x = 0; x < columns; x++) {
        const midX = triangleWidth * x
        const minX = midX - positionOffset
        const maxX = midX + positionOffset
        const posX = Math.floor(Math.random() * (maxX - minX + 1) + minX)
        const width = Math.floor(Math.random() * (max - min + 1) + min)
        const height = Math.floor(Math.random() * (max - min + 1) + min)
        const colorIndex = Math.floor(
          Math.random() * Config.triangleColors.length
        )
        const colorClassName = Config.triangleColors[colorIndex]
        let blendMode = null
        if (Math.floor(Math.random() * 4) === 3) {
          blendMode = 'add'
        } else if (Math.floor(Math.random() * 3) === 2) {
          blendMode = 'add'
        }
        let speed = Math.random()
        let startPosX = posX
        let startPosY = posY
        if (posX > frameWidth / 2) {
          startPosX = Math.floor(frameWidth + 600)
        } else {
          startPosX = -600
        }
        startPosY = Math.floor(Math.random() * 2000) - 1000 + posY
        const delay = Math.random()
        const rotation = Math.floor(Math.random() * 360)
        triangles.push({
          peakX: width,
          peakY: height,
          mid: triangleWidth,
          posX: startPosX,
          posY: startPosY,
          colorClassName: colorClassName,
          blendMode: blendMode,
          endPosX: posX,
          endPosY: posY,
          delay: delay,
          opacity: 1,
          rotation: rotation,
          initialPosY: posY,
          speed: speed,
        })
      }
    }
  }
  return triangles
}

export default HeaderUnderlay
