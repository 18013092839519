import React from 'react'
import PropTypes from 'prop-types'

import { motion } from 'framer-motion'

const ModuleTriangle = ({
  peakY,
  peakX,
  midPoint,
  className,
  id,
  styles,
  colorClassName,
  startPosX,
  startPosY,
  endPosX,
  endPosY,
  rotation,
  delay,
  opacity,
  scale = 1,
}) => {
  return (
    <motion.svg
      height={peakY}
      width={peakX}
      style={styles}
      id={id}
      className={className}
      initial={{ x: startPosX, y: startPosY, rotate: 0, opacity: 1, scale: 1 }}
      animate={{
        x: endPosX,
        y: endPosY,
        rotate: rotation,
        opacity: opacity,
        scale: scale,
      }}
      transition={{ delay: delay, duration: 0.75, ease: 'circIn' }}
    >
      <polygon
        points={`0,0 ${midPoint},0 ${peakX}, ${peakY}`}
        className={colorClassName}
      />
    </motion.svg>
  )
}

ModuleTriangle.propTypes = {
  peakY: PropTypes.number,
  peakX: PropTypes.number,
  midPoint: PropTypes.number,
  styles: PropTypes.object,
  className: PropTypes.string,
  id: PropTypes.string,
  colorClassName: PropTypes.string,
  startPosX: PropTypes.number,
  startPosY: PropTypes.number,
  endPosX: PropTypes.number,
  endPosY: PropTypes.number,
  rotation: PropTypes.number,
  delay: PropTypes.number,
  opacity: PropTypes.number,
  falling: PropTypes.bool,
  scale: PropTypes.number,
}

export default ModuleTriangle
