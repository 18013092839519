import React, { useState } from 'react'
import { motion } from 'framer-motion'

import './Product.scss'

const Product = ({
  product,
  isOpen,
  onClick,
  onProductClose,
}) => {
  const target = React.useRef(null)
  const [productActive, setProductActive] = useState(false)
  
  // const [rotateValue, setRotateValue] = useState({x: 0, z: 0});
  // const [rotateHover, setRotateHover] = useState({x: 0, z: 0});
  // const mouseMove = (e) => {
  //     setMouseDetails({offsetX: e.clientX, offsetY: e.clientY});
  // }
  // const setMouseDetails = (e) => {
  //     const originX = size.x + Math.floor(size.width / 2);
  //     const originY = size.y + Math.floor(size.height / 2);
  //     const rotate = {};
  //     rotate.z = -(((e.offsetX / originX / 2).toFixed(2) * 20) - 10);
  //     rotate.x = -(((e.offsetY / originY / 2).toFixed(2) * 80) - 40);

  //     //console.log(rotateHover);
  //     setRotateHover(rotate);
  // }
  // const resetHover = () => {
  //     setRotateHover({x: 0, z: 0});
  // }

  // useEffect(() => {
  //     if (productActive) {
  //       let timerId

  //       const f = () => {
  //         setRotateHover(rotateValue);
  //         timerId = requestAnimationFrame(f);
  //       }

  //       timerId = requestAnimationFrame(f);

  //       return () => cancelAnimationFrame(timerId);
  //     }
  //   }, [rotateValue]);
  return (
    <motion.div
      onHoverStart={(e, i) => {
        setProductActive(isOpen ? false : true)
      }}
      onHoverEnd={(e, i) => {
        setProductActive(false)
      }}
      onClick={() => {
        setProductActive(false)
        onClick()
      }}
      className={`product ${isOpen ? 'product--open' : ''} ${
        productActive ? 'product--hover' : ''
      }`}
      ref={target}
    >
      <motion.div className="product__container">
        <motion.div transition={{ duration: 0.45 }} className="product__underlay">
          <motion.h2 className="product__title">{product.title}</motion.h2>
          <motion.div className="product__price">
            {isOpen && '$'}
            {product.price}
          </motion.div>
        </motion.div>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.45, delayChildren: 0.45 }}
            className="product__description"
          >
            <motion.div
              initial={{ rotate: '0deg', x: 60 }}
              animate={{ rotate: '1080deg', x: 0 }}
              transition={{ duration: 0.75 }}
              className="product__close"
              onClick={(e) => {
                e.stopPropagation()
                onProductClose()
              }}
            >
              &#10006;
            </motion.div>
            <motion.div
              className="product__details"
              initial={{ opacity: 0, x: 40 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.55, duration: 0.5 }}
            >
              {product.details}
            </motion.div>
            <motion.div
              className="product__materials"
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.65, duration: 0.5 }}
            >
              {product.materials}
            </motion.div>
            <motion.p
              className="product__desc"
              initial={{ opacity: 0, x: 60 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.75, duration: 0.5 }}
            >
              {product.description}
            </motion.p>
            <motion.a
              initial={{ opacity: 0, x: 70 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.85, duration: 0.5 }}
              href={product.url}
            >
              Check it out!
            </motion.a>
          </motion.div>
        )}
        <motion.div
          transition={{ duration: 0.45 }}
          className="product__image-container"
        >
          <motion.img className="product__image" src={product.image} alt={product.title} />
        </motion.div>
      </motion.div>
    </motion.div>
  )
}

export default Product
